import React from "react";
import "./index.scss";
import { useObserver } from "mobx-react-lite";
import { useState } from "react";

export const TradingPairIcon = (props) => {
  const [imgErr, setImgErr] = useState(false);
  return useObserver(() => (
    <div className="component__trading__pair__icon flex w-16" style={{ height: props.height }}>
      <img className="app-avatar rounded-full token__icon" src={imgErr ? "/image/token_custom.png" : props.tokenIcon} alt="" onError={(e) => setImgErr(true)} />
      <img className="app-avatar iotx__icon" src="/image/iotx.png" alt="token icon" />
    </div>
  ));
};
