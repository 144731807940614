import { createClient } from "../../../generated/app-gql/createClient";
import { request } from "graphql-request";

export const appClient = createClient({
  fetcher: ({ query, variables }) => request("/api-gateway", query, variables).then((data) => ({ data })),
});

export enum ActionType {
  ALL = "ALL",
  BUY_COIN = "BUY_COIN",
  BUY_TOKEN = "BUY_TOKEN",
  ADD = "ADD",
  REMOVE = "REMOVE",
}
