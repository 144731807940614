import React from "react";
import { useObserver } from "mobx-react-lite";
import "./index.scss";
import { useStore } from "../../../common/store/index";
import { ActionsTable, Overview, TokenTable } from "../../components";
import { Input } from "antd";
import { NavLink } from "react-router-dom";
import { publicConfig } from "../../../../configs/public";

export const Home = () => {
  const { lang } = useStore();
  return useObserver(() => (
    <div className="page__home screen">
      <div className="page__home__title c-gray text-left font-roboto font-medium">{lang.t("mimo_protocol_analytics")}</div>
      <div className={`page__home__search_input__wrapper bg-white-10 rounded ${publicConfig.ENABLE_SEARCH_INPUT ? "" : "hidden"}`}>
        <Input className="page__home__search_input bg-white-10" suffix={<img className="page__home__search_input__icon" src="/image/icon_search.png" />} />
      </div>
      <div className="page__home__overview">
        <Overview />
      </div>
      <div className="mb-24">
        <div className="flex flex-row justify-between items-center mb-4">
          <div className="text-3xl lg:text-2xl c-gray font-roboto font-medium">{lang.t("top_tokens")}</div>
          <p className="c-third text-2xl lg:text-base font-roboto mb-0">
            <NavLink className="c-third text-2xl lg:text-base font-roboto" to={"/tokens"}>
              {lang.t("see_all")}
            </NavLink>
          </p>
        </div>
        <TokenTable />
      </div>

      <div className="mb-24">
        <div className="flex flex-row justify-between items-center mb-4">
          <h3 className="text-3xl lg:text-2xl c-gray font-roboto font-medium">{lang.t("actions")}</h3>
          <p className="c-third text-2xl lg:text-base font-roboto">
            <NavLink className="c-third text-2xl lg:text-base font-roboto" to={"/actions"}>
              {lang.t("see_all")}
            </NavLink>
          </p>
        </div>
        <ActionsTable />
      </div>
    </div>
  ));
};
