import fn from "fromnow";
import { LangStore } from "../store/lang";

export function fromNow(ts: string | undefined): string {
  if (!ts) {
    return "";
  }
  return fn(new Date(ts), { max: 1, suffix: true });
}

export function translateFn(ts: string, lang?: LangStore): string {
  const keyMessage = ["years", "year", "months", "month", "days", "day", "hours", "hour", "minutes", "minute", "ago", "just now"];
  let text = fromNow(ts);
  if (lang) {
    keyMessage.map((value) => {
      // @ts-ignore
      text = text.replace(value, lang.t(`time.fn.${value.replace(" ", "")}`));
    });
  }
  return text;
}
