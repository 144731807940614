import Antenna from "iotex-antenna";
import { publicConfig } from "../../../configs/public";
import { SignerPlugin } from "iotex-antenna/lib/action/method";

export class AntennaUtils {
  public static defaultContractOptions = {
    gasLimit: "20000000",
    gasPrice: "1000000000000",
  };
  public static antenna: Antenna | null = null;
  public static signerPlugin: SignerPlugin | null = null;

  static getAntenna(): Antenna {
    if (this.antenna) {
      return this.antenna;
    }
    const antenna = new Antenna(publicConfig.IOTEX_CORE_ENDPOPINT);
    this.antenna = antenna;
    return antenna;
  }

  static async getAccounts() {
    return this.signerPlugin ? this.signerPlugin.getAccounts() : [];
  }
}
