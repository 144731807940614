import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Area, AreaChart, Bar, BarChart, CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { useObserver } from "mobx-react-lite";
import { Button, Select } from "antd";
import { isMobile } from "react-device-detect";
import { useStore } from "../../../common/store/index";
import BN from "bignumber.js";

import "./index.scss";
import { utils } from "../../../common/utils/index";
import { publicConfig } from "../../../../configs/public";
import { analyticsClient } from "../../../common/utils/gql";
import { fromRau, validateAddress } from "iotex-antenna/lib/account/utils";
import { Dict } from "../../../type";
import { abbreviateNumber, tickDateFormatter } from "../../utils/format";
import moment from "moment";
import { helper } from "../../../common/utils/helper";
import { numberWithThousandsSeparators } from "../../utils/number";
import { Token } from "../../../../generated/gql/schema";

const { Option } = Select;

export enum ChartQueryTypeT {
  LIQUIDITY,
  VOLUME,
  TOKEN2IOTX,
  IOTX2ToKen,
}

export type DurationT = { value: number; text: string };
export type ChartTypeT = { value: ChartQueryTypeT; text: string; onClick?: (event) => void; hidden?: boolean };

export const LiquidityChart = ({
  durations,
  types,
  showTitle = true,
  chartWidth,
  chartHeight,
  variables,
  token,
  queryName = "liquidities",

}: {
  durations?: DurationT[];
  types?: ChartTypeT[];
  showTitle?: boolean;
  chartWidth?: number;
  chartHeight?: number;
  variables?: Dict;
  queryName?: string;
  token?: Token;
}) => {
  const { lang } = useStore();
  const maxDuration = utils.helper.datetime.dateDiffInDays(new Date(), publicConfig.START_ANALYTIC_DATE);

  const durationList = durations
    ? durations
    : [
        { value: 7, text: lang.t("oneWeek") },
        { value: maxDuration > 30 ? 30 : maxDuration, text: lang.t("oneMonth") },
        { value: maxDuration, text: lang.t("allTime") },
      ];
  const [currentDurationIndex, setCurrentDurationIndex] = useState(0);
  const [data, setData] = useState([]);
  const [dayAgo, setDayAgo] = useState(durationList.length > 0 ? durationList[0].value : 7);
  const [currentType, setCurrentType] = useState(ChartQueryTypeT.LIQUIDITY);
  const [todayLiquidity, setTodayLiquidity] = useState("-");
  const [maxValue, setMaxValue] = useState(0);

  const size = { ...(chartHeight ? { height: `${chartHeight}%` } : {}), ...(chartWidth ? { width: `${chartWidth}%` } : {}) };

  const updateChartData = useCallback(
    (data, fillZero?: boolean) => {
      const renderData = data.map((item, index) => {
        return {
          amount: Number(fromRau(item.amount, "iotx")),
          date: item.date,
          index,
        };
      });
      if (!fillZero) {
        setData(renderData);
        return renderData;
      }
      const now = new Date();
      const chartData = [];
      for (let i = 0; i < dayAgo; i++) {
        const cDate = new Date();
        cDate.setDate(now.getDate() - (dayAgo - i - 1));
        if (renderData.length > 0) {
          const diff = helper.datetime.dateDiffInDays(cDate, moment.utc(renderData[0].date, "YYYY-MM-DD").toDate(), true);
          if (diff <= 0) {
            chartData.push(renderData.shift());
            continue;
          }
        }
        chartData.push({ amount: 0, date: cDate });
      }
      const dataList = chartData.map((item, index) => {
        return {
          ...item,
          index,
        };
      });
      setData(dataList);
      return dataList;
    },
    [dayAgo]
  );

  useEffect(() => {
    if (currentType === ChartQueryTypeT.LIQUIDITY) {
      analyticsClient.chain.query[queryName]({
        days: dayAgo,
        ...(variables || {}),
      })
        .execute({
          amount: 0,
          date: false,
        })
        .then((data) => {
          const chartData = updateChartData(data);
          let todayLiquidityText = "-";
          if (chartData.length > 0) {
            todayLiquidityText = numberWithThousandsSeparators(Number(chartData[chartData.length - 1].amount));
          }
          setTodayLiquidity(`${todayLiquidityText} IOTX`);
        });
    } else if (currentType === ChartQueryTypeT.VOLUME) {
      const exchangeAddress = (variables || {}).exchange;
      if (validateAddress(exchangeAddress)) {
        analyticsClient.chain.query
          .volumesOfExchange({
            days: dayAgo,
            exchange: exchangeAddress,
          })
          .execute({
            amount: 0,
            date: false,
          })
          .then((data) => {
            updateChartData(data, true);
          });
      }
    } else if (currentType === ChartQueryTypeT.IOTX2ToKen || currentType === ChartQueryTypeT.TOKEN2IOTX && token) {
      const exchangeAddress = (variables || {}).exchange;
      if (validateAddress(exchangeAddress)) {
        const liquiditiesOfExchange = analyticsClient.chain.query.liquiditiesOfExchange({
          days: dayAgo,
          exchange: exchangeAddress,
        });
        const tokenBalancesOfExchange = analyticsClient.chain.query.tokenBalancesOfExchange({
          days: dayAgo,
          exchange: exchangeAddress,
        });
        Promise.all([
          liquiditiesOfExchange.execute({
            amount: 0,
            date: false,
          }),
          tokenBalancesOfExchange.execute({
            amount: 0,
            date: false,
          }),
        ]).then(([liquidities, tokenBalances]) => {
          const now = new Date();
          const chartData = [];
          let maxV = new BN(0);
          for (let i = 0; i < dayAgo; i++) {
            const cDate = new Date();
            cDate.setDate(now.getDate() - (dayAgo - i - 1));
            const item = { index: i, date: moment.utc(cDate).format("YYYY MM DD") };
            let found = false;
            if (liquidities.length > 0) {
              const diff = helper.datetime.dateDiffInDays(cDate, moment.utc(liquidities[0].date, "YYYY-MM-DD").toDate(), true);
              if (diff <= 0) {
                item["liquidity"] = liquidities.shift();
                found = true;
              }
            }
            if (!found) {
              item["liquidity"] = { amount: 0, date: cDate };
            }

            found = false;
            if (tokenBalances.length > 0) {
              const diff = helper.datetime.dateDiffInDays(cDate, moment.utc(tokenBalances[0].date, "YYYY-MM-DD").toDate(), true);
              if (diff <= 0) {
                item["tokenBalance"] = tokenBalances.shift();
                found = true;
              }
            }
            if (!found) {
              item["tokenBalance"] = { amount: 0, date: cDate };
            }
            if (item["tokenBalance"].amount && item["liquidity"].amount) {
              const liquidityBN = new BN(fromRau(item["liquidity"].amount,"iotx"));
              const tokenBalanceBN = new BN(item["tokenBalance"].amount).div(10 ** token.decimals);
              const priceBN =
                currentType === ChartQueryTypeT.IOTX2ToKen
                  ? liquidityBN.gt(0)?tokenBalanceBN.div(liquidityBN): new BN(0)
                  : tokenBalanceBN.gt(0)?liquidityBN.div(tokenBalanceBN): new BN(0);
              if (!priceBN.isNaN()) {
                if (priceBN.gt(maxV)) {
                  maxV = priceBN.multipliedBy(1.25);
                }
                item["price"] = priceBN.toNumber();
              }
            }
            chartData.push(item);
          }

          const maxValueNumber = maxV.gt(1) ? Math.floor(maxV.toNumber()) : Math.floor(maxV.toNumber() * 10000000) / 10000000;
          setMaxValue(maxValueNumber);
          setData(chartData);
        });
      }
    }
  }, [dayAgo, currentType]);

  const setDurationItem = (index: number) => {
    setCurrentDurationIndex(index);
    setDayAgo(durationList[index].value);
  };

  const chartElement = useMemo(() => {
    if (currentType === ChartQueryTypeT.LIQUIDITY && data.length > 0) {
      return (
        <AreaChart data={data}>
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#00E100" stopOpacity={0.4} />
              <stop offset="85%" stopColor="#00E10000" stopOpacity={0} />
            </linearGradient>
          </defs>

          <XAxis
            dataKey="date"
            axisLine={{ stroke: "#F2F2F2" }}
            stroke="rgb(161,159,172)"
            tickLine={{ stroke: "transparent" }}
            tick={{ fontSize: "0.875rem", fontWeight: 300 }}
            tickFormatter={tickDateFormatter}
          />
          <YAxis
            orientation="right"
            axisLine={{ stroke: "#F2F2F2" }}
            stroke="rgb(161,159,172)"
            tickLine={{ stroke: "transparent" }}
            tickFormatter={(value) => (value === 0 ? value : abbreviateNumber(value))}
          />
          <Tooltip
            content={(data) => {
              if (!data.active) return null;
              return <div className="c-third text-base bd-third component_liquidity__chart__tooltip">{data.payload && data.payload[0] && formatTooltip(data.payload[0])} IOTX</div>;
            }}
          />
          <Area type="monotone" dataKey="amount" stroke="#00E100" fillOpacity={1} fill="url(#colorUv)" />
        </AreaChart>
      );
    } else if (currentType === ChartQueryTypeT.VOLUME && data.length > 0) {
      return (
        <BarChart data={data}>
          <XAxis
            dataKey="date"
            axisLine={{ stroke: "#F2F2F2" }}
            stroke="rgb(161,159,172)"
            tickLine={{ stroke: "transparent" }}
            tick={{ fontSize: "0.875rem", fontWeight: 300 }}
            tickFormatter={tickDateFormatter}
          />
          <YAxis
            allowDecimals={false}
            orientation="right"
            axisLine={{ stroke: "#F2F2F2" }}
            stroke="rgb(161,159,172)"
            tickLine={{ stroke: "transparent" }}
            tickFormatter={(value) => (value === 0 ? value : abbreviateNumber(value))}
          />
          <Tooltip
            cursor={false}
            content={(data) => {
              if (!data.active) return null;
              return <div className="c-third text-base bd-third component_liquidity__chart__tooltip">{data.payload && data.payload[0] && formatTooltip(data.payload[0])} IOTX</div>;
            }}
          />
          <Bar
            dataKey="amount"
            shape={(options) => {
              const { x, y, width, height, index } = options;
              return <rect id={`amount${index}`} data-index={index} style={{ fill: "rgb(203, 248, 203, 1)" }} x={x} y={y} width={width} height={height} />;
            }}
            onMouseOver={(option) => {
              if (document.getElementById(`amount${option.index}`)) {
                document.getElementById(`amount${option.index}`).style.cssText = "fill: #00e100 !important";
              }
            }}
            onMouseOut={(option) => {
              if (document.getElementById(`amount${option.index}`)) {
                document.getElementById(`amount${option.index}`).style.cssText = "fill: rgb(203, 248, 203, 1) !important";
              }
            }}
          />
        </BarChart>
      );
    } else if ((currentType === ChartQueryTypeT.IOTX2ToKen || currentType === ChartQueryTypeT.TOKEN2IOTX) && data.length > 0) {
      return (
        <LineChart data={data}>
          <CartesianGrid dataKey="date" strokeDasharray="3 3" tickFormatter={tickDateFormatter} />
          <XAxis
            dataKey="date"
            axisLine={{ stroke: "#F2F2F2" }}
            stroke="rgb(161,159,172)"
            tickLine={{ stroke: "transparent" }}
            tick={{ fontSize: "0.875rem", fontWeight: 300 }}
            tickFormatter={tickDateFormatter}
          />
          <YAxis
            domain={[0, maxValue]}
            orientation="right"
            axisLine={{ stroke: "#F2F2F2" }}
            stroke="rgb(161,159,172)"
            tickLine={{ stroke: "transparent" }}
            tickFormatter={(value) => (value === 0 ? value : abbreviateNumber(value))}
          />
          <Tooltip
            cursor={false}
            content={(data) => {
              if (!data.active) return null;
              return <div className="c-third text-base bd-third component_liquidity__chart__tooltip">{data.payload && data.payload[0] && formatTooltip(data.payload[0])}</div>;
            }}
          />
          <Line dataKey="price" type="monotone" stroke="#00E100" />
        </LineChart>
      );
    }
    return <></>;
  }, [currentType, data]);

  return useObserver(() => (
    <div className="component_liquidity__chart h-full">
      <div>
        <div className="flex flex-row justify-between">
          {showTitle && (
            <div>
              <div className="component_liquidity__chart__type c-gray font-roboto">{lang.t("liquidity")}</div>
              <div className="component_liquidity__chart__value c-gray font-plex-sans">{todayLiquidity}</div>
            </div>
          )}
          {types && (
            <div className="display-mobile">
              <Select
                defaultValue={currentType}
                onChange={(value: ChartQueryTypeT) => {
                  setData([]);
                  setCurrentType(value);
                }}
              >
                {types
                  .filter((type) => !(type.hidden === true))
                  .map((type) => (
                    <Option key={`option-type-${type.value}`} value={type.value}>
                      {type.text}
                    </Option>
                  ))}
              </Select>
            </div>
          )}
          <div className="display-mobile">
            <Select
              defaultValue={currentDurationIndex}
              onChange={(value: number) => {
                setDurationItem(value);
              }}
            >
              {durationList.map((duration, index) => (
                <Option key={`chart-option-duration-${duration.value}-${index}`} value={index}>
                  {duration.text}
                </Option>
              ))}
            </Select>
          </div>
        </div>
        {!isMobile && (
          <div className="display-desktop">
            <div className="component__liquidity__chart_btn-wrapper mb-4 grid grid-cols-3 gap-1">
              <div className="col-span-2 text-left">
                {types &&
                  types
                    .filter((type) => !(type.hidden === true))
                    .map((type) => (
                      <Button
                        key={`button-duration-${type.value}`}
                        className={`component__volume__chart_btn-wrapper__btn ml-4 ${currentType === type.value ? "active" : "inactive"}`}
                        onClick={(event) => {
                          if (type.value !== currentType) {
                            setData([]);
                          }
                          setCurrentType(type.value);
                          type.onClick && type.onClick(type.value);
                        }}
                      >
                        {type.text}
                      </Button>
                    ))}
              </div>
              <div className={`col-span-${types ? 1 : 3} text-right`}>
                {durationList.map((duration, index) => (
                  <Button
                    key={`duration-${duration.value}-${index}`}
                    className={`component__volume__chart_btn-wrapper__btn ml-4 ${currentDurationIndex === index ? "active" : "inactive"}`}
                    onClick={() => setDurationItem(index)}
                  >
                    {duration.text}
                  </Button>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
      <ResponsiveContainer {...size}>{chartElement}</ResponsiveContainer>
    </div>
  ));
};

export const formatTooltip = (dataItem) => {
  return (
    <>
      {tickDateFormatter(dataItem.payload.date)}：<b>{dataItem.value && utils.helper.string.formatBN(dataItem.value)}</b>
    </>
  );
};
