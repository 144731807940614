import React from "react";
import { ResponsiveContainer } from "recharts";
import { useObserver } from "mobx-react-lite";
import { useStore } from "../../../common/store/index";

import "./index.scss";
import { ChartQueryTypeT, LiquidityChart } from "../LiquidityChart/index";
import { Token } from "../../../../generated/gql/schema";

export const PairDetailChart = ({ exchangeAddress, token }: { exchangeAddress: string; token: Token }) => {
  const { lang } = useStore();

  return useObserver(() => (
    <div className="component__pair__detail__chart h-full">
      <ResponsiveContainer height="90%" width="95%">
        <LiquidityChart
          showTitle={false}
          types={[
            { value: ChartQueryTypeT.LIQUIDITY, text: lang.t("liquidity") },
            { value: ChartQueryTypeT.VOLUME, text: lang.t("volume") },
            { value: ChartQueryTypeT.IOTX2ToKen, text: `IOTX/${token?.symbol || ""}`, hidden: false },
            { value: ChartQueryTypeT.TOKEN2IOTX, text: `${token?.symbol || ""}/IOTX`, hidden: false },
          ]}
          chartHeight={100}
          variables={{ exchange: exchangeAddress }}
          queryName={"liquiditiesOfExchange"}
          token={token}
        />
      </ResponsiveContainer>
    </div>
  ));
};
