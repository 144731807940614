import React, { useEffect, useState } from "react";
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { useObserver } from "mobx-react-lite";
import { Button, Select } from "antd";
import { isMobile } from "react-device-detect";
import { useStore } from "../../../common/store/index";

import "./index.scss";
import { analyticsClient } from "../../../common/utils/gql";
import { fromRau } from "iotex-antenna/lib/account/utils";
import dateFormat from "dateformat";
import { publicConfig } from "../../../../configs/public";
import { utils } from "../../../common/utils/index";
import BN from "bignumber.js";
import { abbreviateNumber, tickDateFormatter } from "../../utils/format";
import { formatTooltip } from "../LiquidityChart/index";

const { Option } = Select;

export const VolumeChart = () => {
  const { lang, stats } = useStore();
  const [data, setData] = useState([]);
  const [statsData, setStatsData] = useState(null);
  const [dayAgo, setDayAgo] = useState(7);

  const setAllTime = () => {
    const diffDays = utils.helper.datetime.dateDiffInDays(new Date(), publicConfig.START_ANALYTIC_DATE);
    setDayAgo(diffDays);
  };

  useEffect(() => {
    stats.queryStats24h().then((stats) => {
      setStatsData(stats);
    });
    analyticsClient.chain.query
      .volumes({
        days: dayAgo,
      })
      .execute({
        amount: 0,
        date: false,
      })
      .then((data) => {
        const renderData = data.map((item, index) => {
          return {
            amount: Number(fromRau(item.amount, "iotx")),
            date: item.date,
            index,
          };
        });
        setData(renderData);
      });
  }, [dayAgo]);

  return useObserver(() => (
    <div className="component__volume__chart h-full">
      <div className="flex flex-row justify-between">
        <div>
          <div className="component__volume__chart__type c-gray font-roboto">{lang.t("24h_volume")}</div>
          <div className="component__volume__chart__value c-gray font-plex-sans">{`${utils.helper.string.formatBN(new BN(fromRau(statsData && statsData.volume, "iotx")))} IOTX`}</div>
        </div>
        <div className="display-mobile">
          <Select
            defaultValue={dayAgo}
            onChange={(value: number | string) => {
              if ("allTime" === value) {
                setAllTime();
              } else if (typeof value === "number") {
                setDayAgo(value);
              }
            }}
          >
            <Option value="allTime">{lang.t("allTime")}</Option>
            <Option value={7}>{lang.t("oneWeek")}</Option>
          </Select>
        </div>
      </div>
      <div className="display-desktop">
        <div className="component__volume__chart_btn-wrapper mb-4 text-right">
          <Button className={`component__volume__chart_btn-wrapper__btn mr-4 ${dayAgo === 7 ? "active" : "inactive"}`} onClick={() => setDayAgo(7)}>
            1 Week
          </Button>
          <Button className={`component__volume__chart_btn-wrapper__btn ${dayAgo === 7 ? "inactive" : "active"}`} onClick={() => setAllTime()}>
            All time
          </Button>
        </div>
      </div>
      <ResponsiveContainer height="70%" width={isMobile ? "100%" : "90%"}>
        <BarChart data={data}>
          <XAxis
            dataKey="date"
            axisLine={{ stroke: "#F2F2F2" }}
            stroke="rgb(161,159,172)"
            tickLine={{ stroke: "transparent" }}
            tick={{ fontSize: "0.875rem", fontWeight: 300 }}
            tickFormatter={tickDateFormatter}
          />
          <YAxis
            allowDecimals={false}
            orientation="right"
            axisLine={{ stroke: "#F2F2F2" }}
            stroke="rgb(161,159,172)"
            tickLine={{ stroke: "transparent" }}
            tickFormatter={(value) => (value === 0 ? value : abbreviateNumber(value))}
          />
          <Tooltip
            cursor={false}
            content={(data) => {
              if (!data.active) return null;
              return <div className="c-third text-base bd-third component__volume__chart__tooltip">{data.payload && data.payload[0] && formatTooltip(data.payload[0])} IOTX</div>;
            }}
          />
          <Bar
            dataKey="amount"
            shape={(options) => {
              const { x, y, width, height, index } = options;
              return <rect id={`amount${index}`} data-index={index} style={{ fill: "rgb(203, 248, 203, 1)" }} x={x} y={y} width={width} height={height} />;
            }}
            onMouseOver={(option) => {
              document.getElementById(`amount${option.index}`).style.cssText = "fill: #00e100 !important";
            }}
            onMouseOut={(option) => {
              document.getElementById(`amount${option.index}`).style.cssText = "fill: rgb(203, 248, 203, 1) !important";
            }}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  ));
};
