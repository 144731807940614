import React from "react";
import { useObserver } from "mobx-react-lite";
import { Input } from "antd";
import "./index.scss";
import { useStore } from "../../../common/store/index";
import { TokenTable } from "../../components";
import { publicConfig } from "../../../../configs/public";

export const Tokens = () => {
  const { lang } = useStore();

  return useObserver(() => (
    <div className="page__tokens screen">
      <div className="lg:flex lg:flex-row lg:justify-between lg:mb-4">
        <div className="page__title c-gray text-left font-roboto font-medium">{lang.t("top_tokens")}</div>
        <div className={`page__search__input__wrapper bg-white-10 rounded ${publicConfig.ENABLE_SEARCH_INPUT ? "" : "hidden"}`}>
          <Input className="page__search__input bg-white-10" suffix={<img className="page__search__input__icon" src="/image/icon_search.png" />} />
        </div>
      </div>
      <TokenTable pageSize={30} />
    </div>
  ));
};
