import React, { useEffect, useState } from "react";
import { useObserver } from "mobx-react-lite";
import "./index.scss";
import { LiquidityChart } from "../LiquidityChart";
import { VolumeChart } from "../VolumeChart";
import { useStore } from "../../../common/store/index";
import { numberWithThousandsSeparators } from "../../utils/number";

export const Overview = () => {
  const { stats } = useStore();
  const [transaction24, setTransaction24] = useState("-");
  const [numOfPairs, setNumOfPairs] = useState("-");
  const [iotxPrice, setIotxPrice] = useState("-");
  const [currentHeight, setCurrentHeight] = useState("-");

  useEffect(() => {
    stats.queryStats24h().then((responseStats) => setTransaction24(`${numberWithThousandsSeparators(responseStats.numOfTransations)}`));
    stats.countValidExchanges().then((numOfPairs) => setNumOfPairs(`${numOfPairs}`));
    stats.queryIotxPrice().then((iotxPrice) => setIotxPrice(`$${Number(iotxPrice).toFixed(4)}`));
    stats.queryHeight().then((currentHeight) => setCurrentHeight(`${numberWithThousandsSeparators(Number(currentHeight))}`));
  }, []);
  return useObserver(() => (
    <div className="component__overview">
      <div className="component__overview__header lg:flex lg:flex-row text-left flex-wrap">
        <p className="component__overview__header__text c-gray-10 font-roboto">IOTX Price: {iotxPrice}</p>
        <p className="component__overview__header__text c-gray-10 font-roboto">Transactions(24H): {transaction24}</p>
        <p className="component__overview__header__text c-gray-10 font-roboto">Pairs: {numOfPairs}</p>
        <p className="component__overview__header__text c-gray-10 font-roboto">Current Height: {currentHeight}</p>
      </div>
      <div className="lg:flex lg:flex-row w-full">
        <div className="component__overview__chart__area pr-12">
          <LiquidityChart chartHeight={70} />
        </div>
        <div className="component__overview__chart__area">
          <VolumeChart />
        </div>
      </div>
    </div>
  ));
};
