import { Contract, Options } from "iotex-antenna/lib/contract/contract";
import { AntennaUtils } from "../utils/antanna";
import { ABIDefinition } from "iotex-antenna/lib/contract/abi";

export class IContract extends Contract {
  _abi: any;
  constructor(jsonInterface?: Array<ABIDefinition>, address?: string, options?: Options) {
    super(jsonInterface, address, options);
    this._abi = jsonInterface;
  }

  public async readContract({
    method,
    args,
    //@ts-ignore
    contractAddress = this.address,
    //@ts-ignore
    abi = this._abi,
    from,
  }: {
    from: string;
    method: string;
    args?: Array<any>;
    contractAddress?: string;
    abi?: string;
  }): Promise<any> {
    return AntennaUtils.getAntenna().iotx.readContractByMethod(
      {
        from,
        contractAddress,
        abi,
        method,
      },
      ...(args || [])
    );
  }
}
