import React from "react";
import "./index.scss";
import { useStore } from "../../../common/store";
import { useObserver } from "mobx-react-lite";
import { NavLink, matchPath, useHistory } from "react-router-dom";
import { LinkButton } from "../Elements/buttons";

export const Navbar = () => {
  const { lang } = useStore();
  const history = useHistory();

  const MENU_ITEMS = [
    { id: "overview", title: lang.t("overview"), href: "/", isExact: true },
    { id: "tokens", title: lang.t("tokens"), href: "/tokens", isExact: true },
    { id: "actions", title: lang.t("actions"), href: "/actions", isExact: true },
  ];

  const SOCIAK_LINKS = [
    {
      src: "https://docs.mimo.finance/",
    },
    {
      src: "https://t.me/mimoprotocol",
    },
    {
      src: "https://twitter.com/mimoprotocol",
    },
  ];

  return useObserver(() => (
    <div className="component__navbar navbar-width bg-white-10 h-full">
      <div className="component__navbar__content pt-8 pb-8 flex flex-col justify-between h-full">
        <div>
          <LinkButton href={`https://mimo.exchange`}>
            <img alt="logo" className="component__navbar__content__logo h-12 m-auto" src={"/image/logo_mimo.png"} />
          </LinkButton>
          <div className="component__navbar__content__items">
            {MENU_ITEMS.map((item) => {
              return (
                <NavLink
                  to={item.href}
                  key={item.id}
                  className={`component__navbar__content__item ${
                    !!matchPath(history.location.pathname, {
                      path: item.href,
                      exact: item.isExact,
                    })
                      ? "component__navbar__content__item--active"
                      : ""
                  } text-center block text-xl font-medium font-roboto`}
                >
                  <div className="component__navbar__content__item__text text-left">{item.title}</div>
                </NavLink>
              );
            })}
          </div>
        </div>
        <div className="component__navbar__content__external__links flex mx-auto justify-center">
          {SOCIAK_LINKS.map((item, index) => {
            return (
              <a className="w-8 h-8 mr-4" href={item.src} key={item.src}>
                <span className={`inline-block w-full h-full menu-item-icon menu-item-icon${index + 1}`}></span>
              </a>
            );
          })}
        </div>
      </div>
    </div>
  ));
};
