import { observable, action, computed } from "mobx";
import remotedev from "mobx-remotedev";
import { analyticsClient } from "../utils/gql";
import { Exchange, Stats } from "../../../generated/gql/schema";
import { appClient } from "../utils/app-gql";
import { AntennaUtils } from "../utils/antanna";
import { validateAddress } from "iotex-antenna/lib/account/utils";
import { contracts } from "../contracts/index";
import { XRC20 } from "iotex-antenna/lib/token/xrc20";
import BN from "bignumber.js";
import { publicConfig } from "../../../configs/public";
import { tokenMetasMap } from "../../client/components/TokenTable/index";

export type DictT<T> = {
  [key: string]: T;
};

@remotedev({ name: "stats" })
export class StatsStore {
  @observable height = "";
  @observable stats24h = null;
  @observable numOfPairs = 0;
  @observable iotxPrice = "";
  @observable exchanges: (Exchange | null)[] = undefined;
  @observable exchange2TokenMap: DictT<XRC20> = {};
  @observable exchange2TokenAddressQueueMap: DictT<boolean> = {};

  @action.bound
  async queryHeight(): Promise<string> {
    if (this.height) return this.height;
    return analyticsClient.chain.query.tipHeight.execute().then((height) => {
      this.height = height;
      return this.height;
    });
  }

  @action.bound
  async queryExchanges(): Promise<(Exchange | null)[]> {
    if (this.exchanges) return this.exchanges;
    return analyticsClient.chain.query
      .exchanges({
        pagination: {
          skip: 0,
          first: 100,
        },
      })
      .execute({
        address: false,
        token: { address: false, name: false, symbol: false, decimals: 0 },
        supply: 0,
        balanceOfIOTX: 0,
        balanceOfToken: 0,
        volumeInPast24Hours: 0,
        volumeInPast7Days: 0,
        balanceOfToken24HoursAgo: 0,
        balanceOfIOTX24HoursAgo: 0,
      })
      .then((exchanges) => {
        this.exchanges = exchanges.filter((item) => {
          console.log(item, tokenMetasMap[item.address]);
          if (publicConfig.tokenBlackList.includes(item.token.address)) return false;
          return new BN(item.balanceOfIOTX).gt(0.01);
        });
        return this.exchanges;
      });
  }

  @action.bound
  async queryNumOfPairs(): Promise<number> {
    if (this.numOfPairs) return this.numOfPairs;
    return analyticsClient.chain.query.numOfPairs.execute().then((numOfPairs) => {
      this.numOfPairs = numOfPairs;
      return this.numOfPairs;
    });
  }

  @action.bound
  async countValidExchanges(): Promise<number> {
    return this.queryExchanges().then((exchanges) => exchanges.length);
  }

  @action.bound
  async queryStats24h(): Promise<Stats> {
    if (this.stats24h) return this.stats24h;
    return analyticsClient.chain.query
      .stats({ hours: 24 })
      .execute({
        numOfTransations: 0,
        volume: 0,
      })
      .then((stats) => {
        this.stats24h = stats;
        return stats;
      });
  }

  @action.bound
  async queryIotxPrice(): Promise<string> {
    if (this.iotxPrice) return this.iotxPrice;
    return appClient.chain.query.iotxPrice.execute().then((iotxPrice) => {
      this.iotxPrice = iotxPrice;
      return iotxPrice;
    });
  }

  @action.bound
  async getExchange2TokenMap(): Promise<DictT<XRC20>> {
    if (this.exchange2TokenMap && Object.keys(this.exchange2TokenMap).length > 0) return this.exchange2TokenMap;
    return this.queryExchanges().then(async (exchanges) => {
      const exchangeAddress = exchanges.map((exchange) => exchange.address);
      const xrc20TokenPromises = exchanges.map((exchange) => this.initTokenByExchange(exchange ? exchange.address : ""));
      const tokens = await Promise.all(xrc20TokenPromises);
      for (let i = 0; i < tokens.length; i++) {
        const xrc20Token = tokens[i];
        if (xrc20Token) {
          this.exchange2TokenMap[exchangeAddress[i]] = xrc20Token;
        }
      }
      return this.exchange2TokenMap;
    });
  }

  async initTokenByExchange(exchangeAddress: string): Promise<XRC20 | undefined> {
    let xrc20 = undefined;
    if (validateAddress(exchangeAddress)) {
      let queued = this.exchange2TokenAddressQueueMap[exchangeAddress];
      if (!queued) {
        this.exchange2TokenAddressQueueMap[exchangeAddress] = true;
        const tokenAddress = await contracts.factoryContract.getToken(exchangeAddress);
        if (validateAddress(tokenAddress)) {
          xrc20 = new XRC20(tokenAddress, { provider: AntennaUtils.getAntenna().iotx, signer: AntennaUtils.getAntenna().iotx.signer });
          const [_decimals, symbol, name] = await Promise.all([xrc20.decimals(), xrc20.symbol(), xrc20.name()]);
          const decimals = _decimals.toNumber();
          Object.assign(xrc20, {
            decimals,
            symbol,
            name,
          });
        } else {
          this.exchange2TokenAddressQueueMap[exchangeAddress] = false;
        }
      }
    }
    return xrc20;
  }

  async addTokenToMap(exchangeAddress: string): Promise<boolean> {
    const xrc20Token = await this.initTokenByExchange(exchangeAddress);
    if (xrc20Token) {
      this.exchange2TokenMap[exchangeAddress] = xrc20Token;
      return true;
    }
    return false;
  }
}
