import { numberWithThousandsSeparators } from "../../client/utils/number";

const _MS_PER_DAY = 1000 * 60 * 60 * 24;
const _MS_PER_MINUTE = 1000 * 60;
import BN from "bignumber.js";
import { fromRau } from "iotex-antenna/lib/account/utils";

export const helper = {
  promise: {
    async sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async runAsync<T, U = Error>(promise: Promise<T>): Promise<[U | null, T | null]> {
      return promise
        .then<[null, T]>((data: T) => [null, data])
        .catch<[U, null]>((err) => [err, null]);
    },
  },
  string: {
    truncate(fullStr = "", strLen, separator = "") {
      if (fullStr.length <= strLen) return fullStr;

      separator = separator || "...";

      const sepLen = separator.length;
      const charsToShow = strLen - sepLen;
      const frontChars = Math.ceil(charsToShow / 2);
      const backChars = Math.floor(charsToShow / 2);

      return fullStr.substr(0, frontChars) + separator + fullStr.substr(fullStr.length - backChars);
    },
    formatBN(value: BN | string | number, flexFormat?: boolean) {
      let bn: BN | number = typeof value === "string" ? new BN(value) : value;
      let text;
      let decimals = 3;
      if (bn instanceof BN) {
        if (bn.isNaN()) return "-";
        if (bn.isZero()) return "0";
        if (flexFormat) {
          if (bn.abs().gte(100)) decimals = 1;
          else if (bn.abs().gte(10)) decimals = 2;
          text = bn.toFixed(decimals);
        } else {
          text = numberWithThousandsSeparators(bn.toNumber());
        }
      } else {
        if (flexFormat) {
          if (Math.abs(bn) >= 100) decimals = 1;
          else if (Math.abs(bn) >= 10) decimals = 2;
          text = bn.toFixed(decimals);
        } else {
          text = numberWithThousandsSeparators(bn);
        }
      }
      if (!flexFormat && Number(text) === 0) return bn.toFixed(8);
      return text;
    },
    calculateLiquidity(liquidity: string) {
      return new BN(fromRau(liquidity, "iotx")).multipliedBy(2);
    },
  },
  datetime: {
    dateDiffInDays(a: Date, b: Date, noAbs?: boolean) {
      // Discard the time and time-zone information.
      const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
      const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
      if (noAbs) return Math.floor((utc2 - utc1) / _MS_PER_DAY);
      return Math.abs(Math.floor((utc2 - utc1) / _MS_PER_DAY));
    },
    dateDiffInMinutes(a: Date, b: Date) {
      return Math.abs(Math.floor((a.getTime() - b.getTime()) / _MS_PER_MINUTE));
    },
  },
  img: {
    getImgByAddress({ address }: { address: string }) {
      return `https://raw.githubusercontent.com/iotexproject/iotex-token-metadata/master/images/${address}.png`;
    },
  },
};
