import React, { useState, useEffect } from "react";
import { Table, Avatar } from "antd";
import { ColumnsType, TablePaginationConfig } from "antd/lib/table";
import { useObserver } from "mobx-react-lite";
import { isMobile } from "react-device-detect";

import "./index.scss";
import { useStore } from "../../../common/store/index";
import { useHistory } from "react-router-dom";
import { IPairItem } from "../../../type";

interface IComponentProps {
  pageSize?: number;
}

export const PairsTable = (props: IComponentProps) => {
  const { pageSize = 10 } = props;
  const { lang } = useStore();
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState<TablePaginationConfig>({ pageSize: pageSize, position: ["bottomCenter"], className: "ant-table-pagination app-table-pagination" });
  const [tokenData, setTokenData] = useState([]);

  const history = useHistory();

  const columns: ColumnsType<IPairItem> = [
    {
      title: lang.t("name"),
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <div className="flex flex-row items-center">
          <p className="index-number mr-1">{record.id}</p>
          <Avatar.Group maxCount={2}>
            <Avatar className="app-avatar" src="/image/iotx.png" alt="token icon" />
            <Avatar className="app-avatar" src="/image/iotx.png" alt="token icon" />
          </Avatar.Group>
          <p className="pl-3">{text}</p>
        </div>
      ),
    },
    {
      title: lang.t("liquidity"),
      dataIndex: "liquidity",
      key: "liquidity",
      align: "right",
    },
    {
      title: lang.t("24h_volume"),
      dataIndex: "volume24h",
      key: "volume24h",
      align: "right",
    },
    {
      title: lang.t("7d_volume"),
      dataIndex: "volume7d",
      key: "volume7d",
      align: "right",
    },
    {
      title: lang.t("fees"),
      dataIndex: "fees",
      key: "fees",
      align: "right",
    },
    {
      title: lang.t("1y_fees_liquidity"),
      dataIndex: "averageFee",
      key: "averageFee",
      align: "right",
      render: (text, record) => <p className="c-third">{text}</p>,
    },
  ];

  const mobileColumnKeys = ["name", "liquidity", "volume24h"];

  const mobileColumns = columns.reduce((arry, item) => {
    if (mobileColumnKeys.find((key) => key === item.key)) {
      arry.push(item);
    }
    return arry;
  }, []);

  useEffect(() => {
    setLoading(true);

    let mockData: IPairItem[] = [];
    for (let i = 0; i < 30; i += 1) {
      mockData[i] = {
        id: i + 1,
        name: "IOTX-VITA",
        liquidity: "$112,455,523",
        volume24h: "$112,455,523",
        volume7d: "$580.01m",
        fees: "$380.01",
        averageFee: "+13.80%",
      };
    }
    setTokenData(mockData);

    setLoading(false);
  }, []);

  return useObserver(() => (
    <div className="component__pairs__table">
      <Table
        columns={isMobile ? mobileColumns : columns}
        dataSource={tokenData}
        pagination={pagination}
        onRow={(record: IPairItem, rowIndex) => {
          return {
            onClick: (event) => {
              history.push(`/pairs/${record.id}`);
            },
          };
        }}
      />
    </div>
  ));
};
