import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Select, Spin, Table } from "antd";
import { ColumnsType, TablePaginationConfig } from "antd/lib/table";
import { useObserver } from "mobx-react-lite";
import { isMobile } from "react-device-detect";
import { LoadingOutlined } from "@ant-design/icons";

import "./index.scss";
import { useStore } from "../../../common/store/index";
import { analyticsClient } from "../../../common/utils/gql";
import { Dict } from "../../../type";
import { fromRau } from "iotex-antenna/lib/account/utils";
import { translateFn } from "../../../common/utils/from-now";
import { Action } from "../../../../generated/gql/schema";
import { LinkButton } from "../Elements/buttons";
import { getIotexScanBaseHost } from "../../utils/host";
import { DictT } from "../../../common/store/stats";
import { XRC20 } from "iotex-antenna/lib/token/xrc20";
import { utils } from "../../../common/utils/index";
import BN from "bignumber.js";
import { ActionType } from "../../../common/utils/app-gql";

const { Option } = Select;

interface IComponentProps {
  pageSize?: number;
  queryName?: string;
  variables?: Dict;
}

export const renderAddress = (text, noTruncate?: boolean, type?: string) => (
  <div className={"truncate"}>
    <LinkButton href={`${getIotexScanBaseHost()}/${type || "address"}/${text}`}>{noTruncate ? text : utils.helper.string.truncate(text, 12)}</LinkButton>
  </div>
);

export const ActionsTable = (props: IComponentProps) => {
  const { pageSize = 10, queryName = "actions", variables = {} } = props;
  const { lang, stats } = useStore();
  const [pagination, setPagination] = useState<TablePaginationConfig>({ defaultPageSize: pageSize, position: ["bottomCenter"], className: "ant-table-pagination app-table-pagination" });
  const [loading, setLoading] = useState(false);
  const [actionsData, setActionsData] = useState([]);
  const [type, setType] = useState(ActionType.ALL);
  const [exchange2TokenMap, setExchange2TokenMap] = useState({} as DictT<XRC20>);
  const [updateCount, setUpdateCount] = useState(0);

  const dropdownSelect = true;

  useEffect(() => {
    setLoading(true);
    stats.getExchange2TokenMap().then((value) => {
      setExchange2TokenMap(value);
    });
    analyticsClient.chain.query[queryName]({
      type,
      pagination: {
        skip: 0,
        first: 200,
      },
      ...(variables || {}),
    })
      .execute({
        hash: undefined,
        type: undefined,
        exchange: undefined,
        account: undefined,
        iotxAmount: 0,
        tokenAmount: 0,
        time: undefined,
      })
      .then((data) => {
        const renderData = data.map((item, index) => {
          return { ...item, key: index + 1 };
        });
        setActionsData(renderData);
        setLoading(false);
      });
  }, [type]);

  const renderType = useMemo(() => {
    return (text, record: Action, index) => {
      let token = exchange2TokenMap[record.exchange];
      if (!token) {
        stats.addTokenToMap(record.exchange).then((modified) => {
          if (modified) {
            setUpdateCount(updateCount + 1);
          }
        });
      }
      // @ts-ignore
      return <LinkButton href={`${getIotexScanBaseHost()}/action/${record.hash}`}>{token ? lang.t(`${text}-for-token`, { token: token.symbol || token.name }) : text}</LinkButton>;
    };
  }, [exchange2TokenMap, updateCount]);


  const renderTokenAmount = useMemo(() => {
    return (text, record: Action, index) => {
      let token = exchange2TokenMap[record.exchange];
      let decimals = 0;
      if (token && typeof token.decimals === 'number') {
        // @ts-ignore
        decimals = token.decimals;
      }
      return <span>{decimals ? utils.helper.string.formatBN((new BN(text).div(10 ** decimals))): ""}</span>;
    };
  }, [exchange2TokenMap, updateCount]);

  const columns: ColumnsType<Action> = useMemo(
    () => [
      {
        title: !dropdownSelect ? (
          <div className="component__actions__table__types flex items-center">
            {Object.keys(ActionType).map((itemType) => (
              <Button
                key={ActionType[itemType]}
                type="text"
                className={`component__actions__table__types__type ${type === ActionType[itemType] ? "component__actions__table__types__type--active bg-third c-white" : "c-gray-30 bg-gray-40"}`}
                onClick={() => setType(ActionType[itemType])}
              >
                {lang.t(ActionType[itemType])}
              </Button>
            ))}
          </div>
        ) : (
          <Select defaultValue={ActionType.ALL} onChange={(value) => setType(value)}>
            {Object.keys(ActionType).map((itemType) => (
              <Option key={itemType} value={itemType}>
                {lang.t(ActionType[itemType])}
              </Option>
            ))}
          </Select>
        ),
        dataIndex: "type",
        key: "type",
        width: "14vw",
        minWidth: "120px",
        render: renderType,
      },
      {
        title: lang.t("token_amount"),
        dataIndex: "tokenAmount",
        key: "tokenAmount",
        align: "right",
        width: "14vw",
        minWidth: "120px",
        render:  renderTokenAmount,
      },
      {
        title: lang.t("iotx_amount"),
        dataIndex: "iotxAmount",
        key: "iotxAmount",
        align: "right",
        width: "14vw",
        minWidth: "120px",
        render: (text) => <span>{`${utils.helper.string.formatBN(new BN(fromRau(text, "iotx")))}`}</span>,
      },
      {
        title: lang.t("account"),
        dataIndex: "account",
        key: "account",
        align: "right",
        ellipsis: true,
        render: (text) => renderAddress(text, true),
      },
      {
        title: lang.t("time"),
        dataIndex: "time",
        key: "time",
        align: "right",
        width: "8vw",
        render: (text) => translateFn(text, lang),
      },
    ],
    [renderType]
  );

  const mobileColumnKeys = ["type", "tokenAmount", "time"];
  const mobileColumns = columns.reduce((arry, item) => {
    if (mobileColumnKeys.find((key) => key === item.key)) {
      arry.push(item);
    }
    return arry;
  }, []);

  return useObserver(() => (
    <div className="component__actions__table">
      <Spin spinning={loading} indicator={<LoadingOutlined style={{ fontSize: 24, color: "var(--c-third)" }} spin />}>
        <Table columns={isMobile ? mobileColumns : columns} dataSource={actionsData} pagination={pagination} />
      </Spin>
    </div>
  ));
};
