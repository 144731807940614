import { Exchange } from "../../../generated/gql/schema";
import { utils } from "../../common/utils/index";
import BN from "bignumber.js";

export const numberWithThousandsSeparators = (n: number) => {
  const nf = new Intl.NumberFormat();
  return nf.format(n);
};

export const calculateAPR = (item: Exchange) => {
  let APROfPool: string;
  const liquidity = new BN(item.balanceOfIOTX).multipliedBy(2);
  const calculatedLiquidity = utils.helper.string.calculateLiquidity(item.balanceOfIOTX);
  if (liquidity.isNaN() || liquidity.isZero()) {
    APROfPool = "";
  } else {
    // just use 24hours liquidity
    APROfPool = utils.helper.string.formatBN(new BN(item.volumeInPast24Hours).multipliedBy(0.003).multipliedBy(365).div(liquidity).multipliedBy(100), true);
  }
  return { APROfPool, calculatedLiquidity };
};
