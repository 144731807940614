import { utils } from "../src/common/utils/index";
import { tokenMetadata } from "./metadata";
const {
  NODE_ENV,
  FORCE_HTTPS,
  ANALYTICS_GRAPHQL_APIENDPOINT,
  START_ANALYTIC_DATE,
  FACTORY_CONTRACT_ADDRESS,
  IOTEX_CORE_ENDPOPINT,
  IOTEXSCAN_ENDPOINT,
  IOTEX_NETWORK,
  COIN_MARKET_CAP_API,
} = utils.env.getEnv();

const IotexNetworkType = {
  MAINNET: "MAINNET",
  TESTNET: "TESTNET",
};

const iotexNetwork = IotexNetworkType[IOTEX_NETWORK] || IotexNetworkType.TESTNET;

const IOTEXSCAN_ENDPOINTS = {
  [IotexNetworkType.MAINNET]: "https://iotexscan.io",
  [IotexNetworkType.TESTNET]: "https://testnet.iotexscan.io",
};

const FACTORY_CONTRACT_ADDRESSES = {
  [IotexNetworkType.MAINNET]: "io1w0sytg3w8fl6zewp4r38w4w9dlq6765uyk9s8j",
  [IotexNetworkType.TESTNET]: "io1vu0tq2v6ph5xhwrrpx0vzvg5wt8adfk3ygnxfj",
};

const IOTEX_CORE_ENDPOPINTS = {
  [IotexNetworkType.MAINNET]: "https://api.iotex.one:443",
  [IotexNetworkType.TESTNET]: "https://api.testnet.iotex.one:443",
};

const tokenMetadatas = {
  [IotexNetworkType.MAINNET]: tokenMetadata.mainnet,
  [IotexNetworkType.TESTNET]: tokenMetadata.mainnet,
};

const IS_PROD = NODE_ENV == "production";
const analyticsGraphqlApiendpoint = ANALYTICS_GRAPHQL_APIENDPOINT || "http://localhost:8089/query";
const iotexscanEndpoint = IOTEXSCAN_ENDPOINT || IOTEXSCAN_ENDPOINTS[iotexNetwork] || "https://testnet.iotexscan.io";
const factoryContractAddress = FACTORY_CONTRACT_ADDRESS || FACTORY_CONTRACT_ADDRESSES[iotexNetwork] || "io1vu0tq2v6ph5xhwrrpx0vzvg5wt8adfk3ygnxfj";
const iotexCoreEndpopint = IOTEX_CORE_ENDPOPINT || IOTEX_CORE_ENDPOPINTS[iotexNetwork] || "https://api.testnet.iotex.one:443";

export const publicConfig = {
  IS_PROD,
  IOTEX_CORE_ENDPOPINT: iotexCoreEndpopint,
  IOTEXSCAN_ENDPOINT: iotexscanEndpoint,
  FORCE_HTTPS: utils.env.getBoolean(FORCE_HTTPS),
  tokenMetadata: tokenMetadatas[iotexNetwork] || tokenMetadata.testnet,
  ANALYTICS_GRAPHQL_APIENDPOINT: analyticsGraphqlApiendpoint,
  START_ANALYTIC_DATE: START_ANALYTIC_DATE ? new Date(START_ANALYTIC_DATE) : new Date(2020, 8, 22),
  ENABLE_SEARCH_INPUT: false,
  FACTORY_CONTRACT_ADDRESS: factoryContractAddress,
  defaultTokenImg: "/image/token_custom.png",
  tokenBlackList: ["io1gjn4r3ermzrjukr6m2k9xhka6ag97k9zz072c3", "io1vwpkgm6zm7kclyrluqlfkke9xjjp7u0hpuss3t", "io1xhw6hc3sm0fs8gtqwhj843ytgqthxe9uv4aced", "io1vwlyynswke0t3hyawrpcjjp4gwqamnedllte6v"],
};
