import React, { useEffect, useMemo, useState } from "react";
import { useStore } from "../../../common/store";
import "./index.scss";
import { useObserver } from "mobx-react-lite";
import { Avatar, Button, Col, Input, notification, Row, Tooltip } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { ActionsTable } from "../../components";
import { PairDetailChart } from "../../components/PairDetailChart";
import { TradingPairIcon } from "../../components/TradingPairIcon";
import { useParams } from "react-router-dom";
import { contracts } from "../../../common/contracts/index";
import { fromRau, validateAddress } from "iotex-antenna/lib/account/utils";
import { tokenMetasMap } from "../../components/TokenTable/index";
import { analyticsClient } from "../../../common/utils/gql";
import BN from "bignumber.js";
import { Exchange } from "../../../../generated/gql/schema";
import { publicConfig } from "../../../../configs/public";
import { helper } from "../../../common/utils/helper";
import { utils } from "../../../common/utils";
import copy from "copy-to-clipboard";
import { renderAddress } from "../../components/ActionsTable/index";
import { calculateAPR } from "../../utils/number";
import { QuestionCircleOutlined } from "@ant-design/icons/lib";

export const PairDetails = () => {
  const { lang } = useStore();
  const { tokenAddress } = useParams();
  const [exchangeAddress, setExchangeAddress] = useState("");
  const [exchange, setExchange] = useState(undefined as Exchange | undefined);
  const token = useMemo(() => (exchange ? exchange.token : undefined), [exchange]);
  const tokenName = useMemo(() => `${token?.symbol || " "}`, [token]);
  const pairName = useMemo(() => `${token?.symbol || "  "}/IOTX`, [token]);
  const tokenLogo = useMemo(() => (tokenMetasMap[tokenAddress] && tokenMetasMap[tokenAddress].logoURI) || utils.helper.img.getImgByAddress({ address: tokenAddress }), [tokenAddress]);

  const { poolToken, totalLiquidity, tokenVolumn24h, poolIotx, fee24h, totalLiquidityPercent, fee24hPercent, tokenVolume24hPercent, APROfPool } = useMemo(() => {
    if (!exchange) {
      return {
        poolToken: "-",
        totalLiquidity: "-",
        tokenVolumn24h: "-",
        poolIotx: "-",
        fee24h: "-",
        totalLiquidityPercent: new BN(0),
        fee24hPercent: new BN(0),
        tokenVolume24hPercent: new BN(0),
        APROfPool: "-",
      };
    }

    const balanceOfIOTXBN = new BN(exchange.balanceOfIOTX);
    const balanceOfIOTX24HoursAgoBN = new BN(exchange.balanceOfIOTX24HoursAgo);
    const volumeInPast24HoursBN = new BN(exchange.volumeInPast24Hours);
    const volumeInPast48HoursBN = new BN(exchange.volumeInPast48Hours);

    const stats = {
      totalLiquidity: helper.string.formatBN(utils.helper.string.calculateLiquidity(exchange.balanceOfIOTX)),
      poolToken: helper.string.formatBN(new BN(exchange.balanceOfToken).div(10 ** exchange.token.decimals)),
      tokenVolumn24h: helper.string.formatBN(new BN(fromRau(exchange.volumeInPast24Hours, "iotx"))),
      poolIotx: helper.string.formatBN(new BN(fromRau(exchange.balanceOfIOTX, "iotx"))),
      fee24h: helper.string.formatBN(new BN(fromRau(exchange.balanceOfIOTX, "iotx")).multipliedBy(0.003)),
    };

    const { APROfPool } = calculateAPR(exchange);
    const totalLiquidityPercent = balanceOfIOTX24HoursAgoBN.gt(0)?balanceOfIOTXBN.minus(balanceOfIOTX24HoursAgoBN).div(balanceOfIOTX24HoursAgoBN).multipliedBy(100):new BN(0);

    return {
      ...stats,
      totalLiquidityPercent,
      fee24hPercent: volumeInPast24HoursBN.minus(volumeInPast48HoursBN).div(volumeInPast48HoursBN).multipliedBy(100),
      tokenVolume24hPercent: volumeInPast24HoursBN.minus(volumeInPast48HoursBN).div(volumeInPast48HoursBN).multipliedBy(100),
      APROfPool,
    };
  }, [exchange]);

  const tokenIotxPriceText = useMemo(() => {
    if (exchange && tokenName) {
      const price = new BN(fromRau(exchange.balanceOfIOTX, "iotx")).div(new BN(exchange.balanceOfToken).div(10 ** exchange.token.decimals));
      const priceText = helper.string.formatBN(price);
      return `1 ${tokenName}= ${priceText} IOTX`;
    }
    return "";
  }, [exchange, tokenName]);

  const iotxTokenPriceText = useMemo(() => {
    if (exchange && tokenName) {
      const price = new BN(exchange.balanceOfToken).div(10 ** exchange.token.decimals).div(new BN(fromRau(exchange.balanceOfIOTX, "iotx")));
      const priceText = helper.string.formatBN(price);
      return `1 IOTX= ${priceText} ${tokenName}`;
    }
    return "";
  }, [exchange, tokenName]);

  useEffect(() => {
    if (validateAddress(tokenAddress)) {
      contracts.factoryContract.getExchange(tokenAddress).then((exchangeAddress) => {
        setExchangeAddress(exchangeAddress);
        analyticsClient.chain.query
          .exchange({
            exchange: exchangeAddress,
          })
          .execute({
            address: false,
            token: { address: false, name: false, symbol: false, decimals: 0 },
            supply: 0,
            balanceOfIOTX: 0,
            balanceOfToken: 0,
            volumeInPast24Hours: 0,
            volumeInPast7Days: 0,
            balanceOfToken24HoursAgo: 0,
            balanceOfIOTX24HoursAgo: 0,
            volumeInPast48Hours: 0,
          })
          .then((exchange) => {
            setExchange(exchange);
          });
      });
    }
  }, [tokenAddress]);

  const onCopyAddress = (address) => {
    copy(address);
    notification.open({
      message: "Copied",
      description: utils.helper.string.truncate(address, 12),
      duration: 1,
    });
  };

  return useObserver(() => (
    <div className="page__pair_details screen">
      <div className="flex justify-between items-center mb-10">
        <div className="c-gray-10 text-lg">
          {lang.t("pairs")} → {pairName}
        </div>
        {publicConfig.ENABLE_SEARCH_INPUT && <Input className={`page__pair_details__search__input bg-white-10`} suffix={<img src="/image/icon_search.png" className="w-7" />} />}
      </div>

      <Row className="mb-24">
        <Col sm={12} md={12} className="mb-6">
          <div className="flex items-center">
            <TradingPairIcon height="2.25rem" tokenIcon={tokenLogo} />
            <div className="c-gray text-2-3-xl font-medium">
              {pairName} {lang.t("pair")}
            </div>
          </div>
        </Col>
        <Col sm={12} md={12} className="mb-6">
          <div className="page__pair_details__header__button_group h-full flex justify-end items-center">
            <Button
              type="primary"
              className={`page__pair_details__header__button_group__btn ml-12 bg-third inactive`}
              onClick={() => (location.href = `https://mimo.exchange/add-liquidity?source=iotx&dest=${tokenAddress}`)}
            >
              <PlusCircleOutlined className="text-xs" />
              {lang.t("liquidity")}
            </Button>
            <Button type="primary" className={`page__pair_details__header__button_group__btn ml-8 bg-third active`} onClick={() => (location.href = `https://mimo.exchange/swap?dest=${tokenAddress}`)}>
              {lang.t("trade")}
            </Button>
          </div>
        </Col>
        <div className="flex flex-wrap lg:flex-no-wrap">
          <div className="flex items-center mt-1 mr-10 lg:mr-24">
            <Avatar src={tokenLogo} className="app-avatar-6" icon={<img src={publicConfig.defaultTokenImg} />} />
            <div className="text-lg c-gray-20 ml-3">{tokenIotxPriceText}</div>
          </div>
          <div className="flex items-center mt-1">
            <Avatar src="/image/iotx.png" className="app-avatar-6" />
            <div className="text-lg c-gray-20 ml-3">{iotxTokenPriceText}</div>
          </div>
        </div>
      </Row>
      <div className="mt-2 mb-6 c-gray text-26 font-medium">{lang.t("pair_stats")}</div>
      <Row gutter={16} className="items-stretch">
        <Col sm={24} md={24} lg={24} xl={8}>
          <div className="pt-5 pb-3">
            <div className="text-lg c-gray-10 mb-3">{lang.t("total_liquidity")}</div>
            <div className="flex justify-between items-center">
              <div className="text-2xl c-gray font-medium">{`${totalLiquidity} IOTX`}</div>
              <div className={`text-lg ${totalLiquidityPercent.gte(0) ? "c-third" : "c-warn-10"}`}>{`${helper.string.formatBN(totalLiquidityPercent)} %`}</div>
            </div>
          </div>
          <div className="py-3">
            <div className="text-lg c-gray-10 mb-3">{lang.t("24h_volume")}</div>
            <div className="flex justify-between items-center">
              <div className="text-2xl c-gray font-medium">{`${tokenVolumn24h} IOTX`}</div>
              <div className={`text-lg ${tokenVolume24hPercent.gte(0) ? "c-third" : "c-warn-10"}`}>{`${helper.string.formatBN(tokenVolume24hPercent)} %`}</div>
            </div>
          </div>
          <div className="py-3">
            <div className="text-lg c-gray-10 mb-3">{lang.t("24h_fees")}</div>
            <div className="flex justify-between items-center">
              <div className="text-2xl c-gray font-medium">{`${fee24h} IOTX`}</div>
              <div className={`text-lg ${fee24hPercent.gte(0) ? "c-third" : "c-warn-10"}`}>{`${helper.string.formatBN(fee24hPercent)} %`}</div>
            </div>
          </div>
          <div className="py-3">
            <div className="text-lg c-gray-10 mb-3 flex items-center">
              {lang.t("APROfPool")}
              &nbsp;
              <Tooltip placement="left" title={lang.t("APROfPool_title")}>
                <QuestionCircleOutlined />
              </Tooltip>
            </div>
            <div className="flex justify-between items-center">
              <div className="text-2xl c-gray font-medium">{`${APROfPool} %`}</div>
            </div>
          </div>
          <div className="py-3">
            <div className="text-lg c-gray-10 mb-6">{lang.t("pool_token")}</div>
            <div className="text-2xl c-gray font-medium">
              <div className="flex items-center mb-5">
                <Avatar src={tokenLogo} className="app-avatar-9" icon={<img src={publicConfig.defaultTokenImg} />} />
                <div className="ml-3">{`${poolToken} ${exchange?.token.symbol || ""}`}</div>
              </div>
              <div className="flex items-center">
                <Avatar src="/image/iotx.png" className="app-avatar-9" />
                <div className="ml-3">{`${poolIotx} IOTX`}</div>
              </div>
            </div>
          </div>
        </Col>
        <Col sm={24} md={24} lg={24} xl={16} style={{ width: "100%" }}>
          <div className="h-full px-5 pl-16">{validateAddress(exchangeAddress) && <PairDetailChart exchangeAddress={exchangeAddress} token={token} />}</div>
        </Col>
      </Row>
      <div className="mt-24 mb-4 c-gray text-26 font-medium">{lang.t("actions")}</div>
      <div className="text-center pb-12">{validateAddress(exchangeAddress) && <ActionsTable pageSize={10} queryName={"actionsOfExchange"} variables={{ exchange: exchangeAddress }} />}</div>
      <div className="mt-16 mb-6 c-gray text-26 font-medium">{lang.t("pair_information")}</div>

      <Row gutter={16} className="mt-8 mb-20 pt-8">
        <Col sm={24} md={24} xl={3}>
          <div className="text-lg c-gray-10">{lang.t("pair_name")}</div>
          <div className="mt-6 text-lg c-gray">{pairName}</div>
        </Col>
        <Col sm={36} md={36} xl={9}>
          <div className="text-lg c-gray-10">{lang.t("pair_address")}</div>
          <div className="flex mt-6">
            <div className="text-lg c-gray break-all ant-table-cell-ellipsis">{renderAddress(exchangeAddress, true)}</div>
            <div className="w-6 h-6 ml-2 cursor-pointer" onClick={() => onCopyAddress(exchangeAddress)}>
              <img src="/image/icon_copy.png" />
            </div>
          </div>
        </Col>
        <Col sm={36} md={36} xl={9}>
          <div className="text-lg c-gray-10">{`${tokenName} ${lang.t("address")}`}</div>
          <div className="flex mt-6">
            <div className="text-lg c-gray break-all ant-table-cell-ellipsis">{renderAddress(tokenAddress, true, "token")}</div>
            <div className="w-6 h-6 ml-2 cursor-pointer" onClick={() => onCopyAddress(tokenAddress)}>
              <img src="/image/icon_copy.png" />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  ));
};
