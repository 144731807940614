import React from "react";
import { useObserver, useLocalStore } from "mobx-react-lite";
import { withRouter, RouteComponentProps } from "react-router-dom";

import { Navbar, Header } from "../../components";
import { ClientOnly } from "../ClientOnly";
import "./index.scss";

interface IComponentProps {
  children: Array<JSX.Element> | JSX.Element;
}

export const MainLayout = withRouter((props: IComponentProps & RouteComponentProps) => {
  return useObserver(() => (
    <ClientOnly>
      <div className="layout__main md:flex">
        <div className="display-desktop">
          <Navbar />
        </div>
        <div className="display-mobile">
          <Header />
        </div>
        <div className="layout__main__content bg-white flex-1">{props.children}</div>
      </div>
    </ClientOnly>
  ));
});
