export const tokenMetadata = {
  testnet: [
    {
      symbol: "IOTX",
      label: "iotx",
      address: "iotx",
      logoURI: "/image/iotx.png",
    },
    {
      symbol: "ioETH",
      label: "Wrapped ETH",
      address: "io1jw6eckew7wak75j7vzxldd5ma88n9pxkajecrw",
      logoURI: "https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png",
    },
    {
      symbol: "ioBUSD",
      label: "Binance USD",
      address: "io1nth44knlehx5nna8ezdpeej5clnj25rgcr7slu",
      logoURI: "https://s2.coinmarketcap.com/static/img/coins/64x64/4687.png",
    },
    {
      symbol: "ioPAXG",
      label: "Paxos Gold",
      address: "io1cxd42m8j72nmy0mmruww82vnrgukdzuh0m3wm2",
      logoURI: "https://s2.coinmarketcap.com/static/img/coins/64x64/4705.png",
    },
    {
      symbol: "TEST",
      label: "TEST",
      address: "io1wgt0skt4s3aa8c76z86xhhjs9z07zjarjpw0a8",
      logoURI: "/image/test.png",
    },
    {
      symbol: "TEST1",
      label: "TEST1",
      address: "io105svyzmjwkun3d4jyjek0l3mk9fujc25luuelx",
      logoURI: "/image/test1.png",
    },
    {
      symbol: "8DEC",
      label: "8DEC",
      address: "io1qkzugxvvrzc08hqyycd5c5pug9pja752cg470a",
      logoURI: "",
    },
  ],
  mainnet: [
    {
      symbol: "IOTX",
      label: "iotx",
      address: "iotx",
      logoURI: "/image/iotx.png",
    },
    {
      symbol: "ioETH",
      label: "Wrapped ETH",
      address: "io1qfvgvmk6lpxkpqwlzanqx4atyzs86ryqjnfuad",
      logoURI: "https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png",
    },
    {
      symbol: "ioBUSD",
      label: "Binance USD",
      address: "io14nhfkywdfvl40evgsqnh43ev33q6he8yez8c8a",
      logoURI: "https://s2.coinmarketcap.com/static/img/coins/64x64/4687.png",
    },
    {
      symbol: "VITA",
      label: "VITA",
      address: "io1hp6y4eqr90j7tmul4w2wa8pm7wx462hq0mg4tw",
      logoURI: "/image/vita.png",
    },
    {
      symbol: "ioUNI",
      label: "UNISWAP",
      address: "io1ahh04jn2zkqlug6feh7rpq75a75p3rj42hp4ch",
      logoURI: "https://s2.coinmarketcap.com/static/img/coins/64x64/7083.png",
    },
    {
      symbol: "ioPAXG",
      label: "Paxos Gold",
      address: "io19fsq8e9krrlng4ay5gyq6q5tqfym28yq9ly0fz",
      logoURI: "https://s2.coinmarketcap.com/static/img/coins/64x64/4705.png",
    },
  ],
};
