import React, { useState } from "react";
import { Link, matchPath, useHistory } from "react-router-dom";
import { Button, Popover } from "antd";
import { useObserver } from "mobx-react-lite";
import "./index.scss";
import { useStore } from "../../../common/store";

export const Header = () => {
  const { lang } = useStore();
  const history = useHistory();
  const [visible, setVisible] = useState(false);

  const MENU_ITEMS = [
    { id: "overview", title: lang.t("overview"), href: "/", isExact: true },
    { id: "tokens", title: lang.t("tokens"), href: "/tokens", isExact: true },
    { id: "actions", title: lang.t("actions"), href: "/actions", isExact: true },
    { id: "docs", title: lang.t("docs"), href: "https://docs.mimo.finance/" },
    { id: "telegram", title: lang.t("telegram"), href: "https://t.me/mimoprotocol" },
    { id: "twitter", title: lang.t("twitter"), href: "https://twitter.com/mimoprotocol" },
  ];

  return useObserver(() => (
    <div className="component__header h-20 w-full">
      <div className="component__header__content flex justify-between items-center">
        <img alt="logo" className="component__header__content__logo" src={"/image/logo_mimo.png"} />
        <Popover
          placement="bottomRight"
          title={null}
          trigger="click"
          visible={visible}
          onVisibleChange={() => setVisible(!visible)}
          overlayClassName="component__header__content__popup"
          content={
            <div>
              {MENU_ITEMS.map((item) => (
                <Link
                  to={item.href}
                  key={item.id}
                  onClick={() => {
                    setVisible(!visible);
                  }}
                  className={`component__header__content__popup__item text-2xl font-roboto ${
                    !!matchPath(history.location.pathname, {
                      path: item.href,
                      exact: item.isExact,
                    })
                      ? "component__header__content__popup__item--active"
                      : ""
                  }`}
                >
                  {item.title}
                </Link>
              ))}
            </div>
          }
        >
          <Button className="component__header__content__popup__btn" icon={<img src="/image/icon_menu.png" />} />
        </Popover>
      </div>
    </div>
  ));
};
