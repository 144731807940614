import React, { useState, useEffect, useMemo } from "react";
import { Table, Spin, Tooltip } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { ColumnsType, TablePaginationConfig } from "antd/lib/table";
import { useObserver } from "mobx-react-lite";
import { LoadingOutlined } from "@ant-design/icons";

import "./index.scss";
import { useStore } from "../../../common/store/index";
import { Exchange } from "../../../../generated/gql/schema";
import BN from "bignumber.js";
import { publicConfig } from "../../../../configs/public";
import { utils } from "../../../common/utils/index";
import { TradingPairIcon } from "../TradingPairIcon";
import { useHistory } from "react-router-dom";
import { calculateAPR } from "../../utils/number";
import { fromRau } from "iotex-antenna/lib/account/utils";

export const tokenMetasMap = publicConfig.tokenMetadata.reduce((p, c, i) => {
  p[c.address] = c;
  return p;
}, {});

interface IComponentProps {
  pageSize?: number;
}

type EnhanceExchange = Exchange & { key: number; calculatedLiquidity: BN; APROfPool: string };

export const TokenTable = (props: IComponentProps) => {
  const { pageSize = 10 } = props;
  const { lang, stats } = useStore();
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState<TablePaginationConfig>({ pageSize: pageSize, position: ["bottomCenter"], className: "ant-table-pagination app-table-pagination" });
  const [tokenData, setTokenData] = useState([]);

  const history = useHistory();

  useEffect(() => {
    setLoading(true);
    stats.queryExchanges().then((exchanges) => {
      console.log(tokenMetasMap);
      const renderData: Array<EnhanceExchange> = exchanges
        .sort((a, b) => new BN(b.balanceOfIOTX).minus(new BN(a.balanceOfIOTX)).toNumber())
        .map((item, index) => {
          const { APROfPool, calculatedLiquidity } = calculateAPR(item);
          return { ...item, key: index + 1, APROfPool, calculatedLiquidity };
        });

      setTokenData(renderData);
      setLoading(false);
    });
  }, []);

  const columns: ColumnsType<EnhanceExchange> = [
    {
      title: lang.t("pair"),
      dataIndex: ["token", "symbol"],
      key: "symbol",
      width: 160,
      fixed: "left",
      render: (text, record) => (
        <div className="flex flex-row items-center">
          <p className="index-number mr-1 hidden md:block">{record.key}</p>
          <TradingPairIcon height="1.75rem" tokenIcon={tokenMetasMap[record.token.address]?.logoURI || utils.helper.img.getImgByAddress({ address: record.token.address })} />
          <p
            className="cursor-pointer"
            onClick={(event) => {
              history.push(`/pairs/${record.token.address}`);
            }}
          >{`${text}-IOTX`}</p>
        </div>
      ),
    },
    {
      title: lang.t("liquidity"),
      dataIndex: "calculatedLiquidity",
      key: "calculatedLiquidity",
      align: "right",
      render: (text, record) => <span>{utils.helper.string.formatBN(record.calculatedLiquidity)}</span>,
    },
    {
      title: lang.t("24h_volume"),
      dataIndex: "volumeInPast24Hours",
      key: "volumeInPast24Hours",
      align: "right",
      render: (text, record) => <span>{utils.helper.string.formatBN(new BN(fromRau(text, "iotx")))}</span>,
    },
    {
      title: lang.t("price"),
      dataIndex: "balanceOfToken",
      key: "price",
      align: "right",
      render: (text, record) => {
        const value = new BN(text).eq(0) ? "-" : utils.helper.string.formatBN(new BN(fromRau(record.balanceOfIOTX, "iotx")).div(new BN(text).div(10 ** record.token.decimals)));
        return <span>{value}</span>;
      },
    },
    {
      title: lang.t("24h_fee"),
      dataIndex: "volumeInPast24Hours",
      key: "fee24h",
      align: "right",
      render: (text, record) => <span>{utils.helper.string.formatBN(new BN(fromRau(text, "iotx")).multipliedBy(0.003))}</span>,
    },
    {
      title: () => (
        <div className="flex items-center text-right w-full justify-end">
          <span className="mr-2">{lang.t("APROfPool")}</span>
          <Tooltip placement="left" title={lang.t("APROfPool_title")}>
            <QuestionCircleOutlined />
          </Tooltip>
        </div>
      ),
      dataIndex: "APROfPool",
      key: "APROfPool",
      align: "right",
      width: 140,
      render: (text) => <span>{text ? `${text}%` : `-`} </span>,
    },
  ];

  const mobileColumnKeys = ["symbol", "calculatedLiquidity", "liquidity", "volumeInPast24Hours", "price", "fee24h", "APROfPool"];

  const mobileColumns = columns.reduce((arry, item) => {
    if (mobileColumnKeys.find((key) => key === item.key)) {
      arry.push(item);
    }
    return arry;
  }, []);

  return useObserver(() => (
    <div className="component__token__table">
      <Spin spinning={loading} indicator={<LoadingOutlined style={{ fontSize: 24, color: "var(--c-third)" }} spin />}>
        <Table className="hidden md:block bg-white" columns={columns} dataSource={tokenData} pagination={pagination} scroll={{ x: 1000 }} />
        <Table className="block md:hidden" columns={mobileColumns} dataSource={tokenData} pagination={pagination} scroll={{ x: 1000 }} />
      </Spin>
    </div>
  ));
};
